import React, {useEffect, useState} from "react"
import PropTypes from "prop-types";
import Header from "../components/Header";
import ErrorBoundary from "../components/utility/ErrorBoundary";
import {Box} from "@material-ui/core";
import MobileMenuDrawer from "../components/MenuDrawer";
import Footer from "../components/Footer";
import CssBaseline from "@material-ui/core/CssBaseline";

const headerTabs = [
  {uri: '/', tab: 'ΑΡΧΙΚΗ'},
  {uri: '/printing', tab: 'ΕΚΤΥΠΩΣΕΙΣ'},
  {uri: '/deals', tab: 'ΠΡΟΣΦΟΡΕΣ'},
  {uri: '/for-our-partners', tab: 'ΥΠΗΡΕΣΙΕΣ'}
];
const contactButton = {uri: '/contact-us', button: 'ΕΠΙΚΟΙΝΩΝΙΑ'};

export const headerHeight = 96;

const Layout = ({children, uri}) => {

  const pageTab = () => {
    if (uri) {
      const splitUri = uri.split('/');
      if (splitUri.includes('printing')) return 1
      if (splitUri.includes('deals')) return 2
      if (splitUri.includes('for-our-partners')) return 3
      if (splitUri.includes('contact-us')) return 4
      return 0
    }
    return 0;
  };

  const splitUri = uri.split('/')
  const page = splitUri[splitUri.length - 1]

  const [selected, setSelected] = useState(pageTab);
  const [mobileMenuDrawerOpen, setMobileMenuDrawerOpen] = useState(false);

  useEffect(() => {
    setSelected(pageTab);
  }, [uri]);

  return (
    <ErrorBoundary>
      <CssBaseline/>
      <Header
        height={headerHeight}
        tabs={headerTabs} contact={contactButton}
        selected={selected} setSelected={setSelected}
        color={'#000'}
        headerColor={'#fff'}
        onMenuClick={() => setMobileMenuDrawerOpen(true)}/>
      <main style={{minHeight: '100vh'}}>
        <div style={{height: headerHeight, width: '100%'}}/>
        <Box display='flex' flexDirection='column'>
          {children}
        </Box>
      </main>
      {page !== 'contact-us' &&  <Footer/>}
      <MobileMenuDrawer
        open={mobileMenuDrawerOpen}
        handleClose={() => setMobileMenuDrawerOpen(false)}
        handleOpen={() => setMobileMenuDrawerOpen(true)}
        menuItems={headerTabs}
        contactItem={contactButton}/>
    </ErrorBoundary>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
