import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import MuiLink from "./utility/MuiLink";
import useBreakpoint from "./utility/useBreakpoint";

const useStyles = makeStyles(theme => ({
  rootPaper: {
    borderTopLeftRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2),
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(12),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '227px',
    maxWidth: '85vw',
    height: '100vh',
    overflow: 'auto',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listItem: {
    width: '100% !important',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  listItemText: {
    textAlign: 'center',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginBottom: theme.spacing(4),
    color: theme.palette.common.white
  }
}));

export default function MobileMenuDrawer(props) {
  const {
    open,
    handleClose,
    handleOpen,
    menuItems,
    contactItem,
  } = props;

  const classes = useStyles();

  const isTablet = useBreakpoint('lg', false)

  if (!isTablet) return null

  return (
    <SwipeableDrawer
      ModalProps={{keepMounted: true}}
      PaperProps={{classes: {root: classes.rootPaper}}}
      id='menu-drawer'
      key='menu-drawer'
      anchor="right"
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}>
      <div
        className={classes.content}
        role="presentation"
        id='menu-drawer-presentation'
      >
        <List>
          {menuItems && menuItems.map((item, index) => (
            <ListItem component={MuiLink} to={item.uri} key={index} underline={'none'}
                      className={classes.listItem}
                      onClick={handleClose}>
              <ListItemText primary={item.tab}
                            className={classes.listItemText}
                            primaryTypographyProps={{variant: 'body1'}}/>
            </ListItem>
          ))}
        </List>
        {contactItem &&
        <Button
          className={classes.button}
          component={MuiLink}
          to={contactItem.uri} underline={'none'}
          disableElevation
          variant="contained"
          size="medium"
          color="primary"
          onClick={handleClose}>
          {contactItem.button}
        </Button>
        }
      </div>
    </SwipeableDrawer>
  );
}
