// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-printing-js": () => import("./../src/templates/printing.js" /* webpackChunkName: "component---src-templates-printing-js" */),
  "component---src-templates-product-js": () => import("./../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-deals-js": () => import("./../src/pages/deals.js" /* webpackChunkName: "component---src-pages-deals-js" */),
  "component---src-pages-for-our-partners-js": () => import("./../src/pages/for-our-partners.js" /* webpackChunkName: "component---src-pages-for-our-partners-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ready-solutions-js": () => import("./../src/pages/ready-solutions.js" /* webpackChunkName: "component---src-pages-ready-solutions-js" */),
  "component---src-pages-under-construction-js": () => import("./../src/pages/under-construction.js" /* webpackChunkName: "component---src-pages-under-construction-js" */)
}

