import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import {makeStyles} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import {Menu} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import {Link} from "gatsby";
import clsx from "clsx";
import MuiLink from "./utility/MuiLink";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from '../images/facebook.svg';
import InstagramIcon from '../images/instagram.svg';
import Logo from '../images/logo.png'

const useStyles = makeStyles(theme => ({
  toolbar: {
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
  },
  itemsContainer: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap'
    },
  },
  titleContainer: {
    height: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  title: {
    color: theme.palette.common.black,
    fontSize: 24,
    marginBottom: 6,
    [theme.breakpoints.down('sm')]: {
      fontSize: 22
    },
  },
  tabIndicator: {
    height: 2,
    borderRadius: theme.spacing(1)
  },
  scrollButtons: {
    color: theme.palette.primary.main
  },
  contactIndicator: {
    display: 'none'
  },
  tabRoot: {
    minWidth: 'unset',
    width: 'fit-content'
  },
  tab: {
    textTransform: 'uppercase',
    color: theme.palette.common.black,
    fontWeight: '900',
    fontSize: 14,
    fontFamily: 'Manrope, sans-serif'
  },
  button: {
    fontWeight: '900',
    whiteSpace: 'nowrap',
    borderRadius: 'initial',
    height: theme.spacing(6),
    color: theme.palette.common.black,
    marginLeft: theme.spacing(2)
  },
  selectedButton: {
    background: theme.palette.dark,
    "&:hover": {
      background: theme.palette.dark,
    }
  },
  socialIcon: {
    marginLeft: theme.spacing(1),
    height: theme.spacing(3),
    width: theme.spacing(3)
  },
  titleContentContainer: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    marginRight: 16,
    height: 80
  }
}));

export default function Header(props) {
  const {
    height = 64,
    tabs,
    contact,
    onMenuClick,
    selected = 0,
    setSelected,
    headerColor = 'pink',
    color,
    ...rest
  } = props;

  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(0);
  const [contactEnabled, setContactEnabled] = useState(false);

  const handleChange = (ev, newVal) => {
    setContactEnabled(false);
    setSelectedTab(newVal);
    setSelected(newVal);
  };

  const handleContact = () => {
    handleChange();
    setContactEnabled(true)
  };

  useEffect(() => {
    if (selected !== selectedTab) {
      setSelectedTab(selected);
    }
  }, [selected, selectedTab]);

  const handleHomeButton = () => {
    setContactEnabled(false);
    setSelectedTab(0)
    setSelected(0)
  }

  return (
    <header {...rest}>
      <AppBar position='fixed' elevation={0} style={{backgroundColor: headerColor}}>
        <Toolbar className={classes.toolbar} style={{height: height}}>
          <Grid container alignItems={'center'} className={classes.itemsContainer}>
            <Grid item md={2} className={classes.titleContainer}>
              <Link to={'/'} className={classes.titleContentContainer} onClick={() => handleHomeButton()}>
                <img src={Logo} alt='' className={classes.logo}/>
                <Typography variant='h5' className={classes.title} style={{color: color}}>
                  Malamas Print
                </Typography>
              </Link>
            </Grid>
            <Grid item container md={10} justify='flex-end' alignItems='center'>
              <Hidden smDown initialWidth={'xs'}>
                <Tabs
                  className={classes.tabs}
                  variant="scrollable"
                  scrollButtons="auto"
                  value={tabs && selectedTab < tabs.length ? selectedTab : false}
                  onChange={handleChange}
                  indicatorColor='primary'
                  classes={{
                    scrollButtonsDesktop: classes.scrollButtons,
                    indicator: contactEnabled ? classes.contactIndicator : classes.tabIndicator
                  }}>
                  {tabs && tabs.length > 0 && tabs.map((tab, index) => {
                    return (
                      <Tab
                        to={tab.uri}
                        component={Link}
                        label={tab.tab}
                        className={classes.tab}
                        classes={{root: classes.tabRoot}}
                        style={{height: '100%', color: color}}
                        value={index}
                        key={index}
                      />
                    )
                  })}
                </Tabs>
              </Hidden>
              <MuiLink url='https://www.facebook.com/elite.oe/?ref=br_rs'>
                <img src={FacebookIcon} alt='' className={classes.socialIcon}/>
              </MuiLink>
              <MuiLink url='https://instagram.com/malamasprint.gr?igshid=1i0e94o23sd2b'>
                <img src={InstagramIcon} alt='' className={classes.socialIcon}/>
              </MuiLink>
              <Hidden smDown initialWidth={'xs'}>
                {contact &&
                <Button
                  to={contact.uri}
                  component={MuiLink}
                  underline={'none'}
                  style={{color: '#fff'}}
                  color='primary' disableElevation variant='contained'
                  className={tabs && selectedTab === tabs.length ? clsx(classes.button, classes.selectedButton) : classes.button}
                  onClick={() => handleContact()}>
                  {contact.button}
                </Button>
                }
              </Hidden>
              <Hidden mdUp initialWidth={'xs'}>
                <IconButton color={'primary'} onClick={onMenuClick}>
                  <Menu/>
                </IconButton>
              </Hidden>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </header>
  );
}
