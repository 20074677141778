module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"malamasprintv2","schemas":{"home_page":{"Main":{"homepage_images_group":{"type":"Group","config":{"fields":{"homepage_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"HomePage Image"}}},"label":"HomePage Images Group"}},"carousel_title":{"type":"Text","config":{"label":"Carousel Title","placeholder":"Carousel Title"}},"whoweare_title":{"type":"Text","config":{"label":"WhoWeAre Title","placeholder":"WhoWeAre Title"}},"whoweare_description":{"type":"Text","config":{"label":"WhoWeAre Description","placeholder":"WhoWeAre Description"}},"whoweare_button_text":{"type":"Text","config":{"label":"WhoWeAre Button Text","placeholder":"WhoWeAre Button Text"}}}},"deals":{"Main":{"product_deals_group":{"type":"Group","config":{"fields":{"product_deals":{"type":"Link","config":{"select":"document","customtypes":["product"],"label":"Product Deals","placeholder":"Product Deals"}}},"label":"Product Deals Group"}}}},"product":{"Main":{"uid":{"type":"UID","config":{"label":"uid","placeholder":"uid"}},"category":{"type":"Text","config":{"label":"Category","placeholder":"Category"}},"image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image"}},"title":{"type":"Text","config":{"label":"Title","placeholder":"Title"}}}},"partners":{"Main":{"partners_products_group":{"type":"Group","config":{"fields":{"partner_product":{"type":"Link","config":{"select":"document","customtypes":["product"],"label":"Partner Product","placeholder":"Partner Product"}}},"label":"Partners Products Group"}}}},"services":{"Main":{"services_image_group":{"type":"Group","config":{"fields":{"services_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Services Image"}}},"label":"Services Image group"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Malamas Print","short_name":"Malamas Print","start_url":"/","background_color":"#fff","theme_color":"#2196f3","display":"standalone","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#2196f3","showSpinner":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
