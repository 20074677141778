import React, {useState} from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import MessageIcon from "@material-ui/icons/Message";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import SEO from "../components/utility/SEO";

export const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://localhost:8000/">
        Εργαστήρι Λιθογραφικής Τέχνης
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  paper: {
    margin: theme.spacing(20, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 16
    },
  },
  avatar: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default function SendMessage() {
  const classes = useStyles();

  const [formState, setFormState] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  })

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formState })
    })
      .then(() => {
        setFormState({
          name: '',
          email: '',
          phone: '',
          message: ''
        })
      })
      .catch(() => alert('Κάτι πήγε λάθος. Παρακαλώ δοκιμάστε ξανά.'));
    e.preventDefault()
  }

  return (
    <>
      <SEO title="Επικοινωνήστε μαζί μας"/>
      <Grid container component="main" className={classes.root}>
        <Grid item container justify={'center'} alignItems={"center"} md={5} component={Paper} elevation={0} square>
          <Box className={classes.paper}>
            <Box display={'flex'} alignItems={'center'}>
              <Avatar className={classes.avatar}>
                <MessageIcon/>
              </Avatar>
              <Typography variant="h5">
                Επικοινωνήστε μαζί μας
              </Typography>
            </Box>
            <form onSubmit={handleSubmit} className={classes.form} name="contact" data-netlify="true" data-netlify-honeypot="bot-field">
              <input type="hidden" name="form-name" value="contact" />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Ονοματεπώνυμο"
                name="name"
                autoComplete="name"
                type="text"
              onChange={handleChange}
              value={formState.name}/>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                type="email"
                autoComplete="email"
                onChange={handleChange}
                value={formState.email}/>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="phone"
                label="Τηλέφωνο"
                id="phone"
                onChange={handleChange}
                value={formState.phone}/>
              <TextField
                variant="outlined"
                margin="normal"
                multiline
                rows={4}
                required
                fullWidth
                name="message"
                label="Μήνυμα"
                type="message"
                id="message"
                onChange={handleChange}
                value={formState.message}/>
              <Box display={'flex'} width='100%' justifyContent={'center'}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}>
                  Αποστολή
                </Button>
              </Box>
              <Box mt={5}>
                <Copyright/>
              </Box>
            </form>
          </Box>
        </Grid>
        <Grid item container xs={12} md={7} justify={"center"} alignItems={"center"}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2642.243172674549!2d23.76310808100526!3d38.04302295424011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1989e18cbe483%3A0x85b663768618a12c!2zzpjOtc-MzrTPic-Bzr_PgiDOo8-EzrXPhs6szr3Ov8-FIM6czqzOu86xzrzOsc-CICYgzqPOuc6xIM6VLs6bzpkuzqTOlS4gzp_OlQ!5e0!3m2!1sel!2sgr!4v1590331063020!5m2!1sel!2sgr"
            width="800" height="600" frameBorder="0" style={{border: 0}} allowFullScreen="" aria-hidden="false" title='map'/>
        </Grid>
      </Grid>
    </>
  );
}
