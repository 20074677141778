import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import {Copyright} from "../pages/contact-us";
import Logo from '../images/logo.png'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    height: '25vh',
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
    },
  },
  innerContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  logoContainer: {
    height: 150,
  },
  logo: {
    height: '100%',
    objectFit: 'contain'
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
  },
  leftContainer: {
    height: '100%',
    flex: 1,
    padding: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 16
    },
  },
  rightContainer: {
    height: '100%',
    flex: 1,
    padding: 48,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 16
    },
  },
  detailsContainer: {
    display: 'flex',
    marginBottom: 8
  },
  icon: {
    marginRight: 16
  },
  innerText: {},
  copyright: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    paddingBottom: 16
  }
}));

export default function Footer(props) {
  const {...rest} = props;

  const classes = useStyles();

  return (
    <footer className={classes.root} {...rest}>
      <Box className={classes.innerContainer}>
      <Box className={classes.leftContainer}>
        <Typography variant='h4' className={classes.title}>
          Εργαστήρι Λιθογραφικής Τέχνης
        </Typography>
      </Box>
        <Box className={classes.logoContainer}>
          <img src={Logo} alt='malamas-print-logo' className={classes.logo}/>
        </Box>
      <Box className={classes.rightContainer}>
        <Box className={classes.detailsContainer}>
          <LocationOnIcon className={classes.icon}/>
          <Typography variant='body1' className={classes.innerText}>
            ΕΥΤΕΡΠΗΣ 4, 14121 ΝΕΟ ΗΡΑΚΛΕΙΟ ΑΤΤΙΚΗΣ
          </Typography>
        </Box>
        <Box className={classes.detailsContainer}>
          <EmailIcon className={classes.icon}/>
          <Typography variant='body1' className={classes.innerText}>
            info@malamasprint.gr
          </Typography>
        </Box>
        <Box className={classes.detailsContainer}>
          <PhoneIcon className={classes.icon}/>
          <Typography variant='body1' className={classes.innerText}>
            210-2756694
          </Typography>
        </Box>
        <Box className={classes.detailsContainer}>
          <Typography variant='body1' className={classes.innerText}>
            Ωράριο Λειτουργίας: Δευτέρα-Παρασκευή 08:00-16:00
          </Typography>
        </Box>
      </Box>
      </Box>
      <Box className={classes.copyright}>
        <Copyright/>
      </Box>
    </footer>
  )
}

